import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Explainer from 'blockscope/components/blockscopeCommon/Explainer';

const Title = ({
  titleCapitalize,
  titleTag: TitleTag,
  className,
  breakPoint,
  children,
}) => (
  <TitleTag
    className={classNames(
      {
        'mb-0': !breakPoint,
        'text-capitalize': titleCapitalize,
        [`mb-${breakPoint}-0`]: !!breakPoint,
      },
      className
    )}
  >
    {children}
  </TitleTag>
);

const FalconCardHeader = ({
  title,
  light,
  titleTag,
  titleCapitalize = true,
  titleClass,
  titleExplainerText,
  className,
  breakPoint,
  startEl,
  endEl,
  children,
}) => {
  const TitleExplainer = ({ children }) => {
    if (titleExplainerText) {
      return (
        <Explainer place='right' text={titleExplainerText}>
          <div style={{ display: 'inline-block', cursor: 'help' }}>
            {children}
          </div>
          <span style={{ cursor: 'help' }}>
            <FontAwesomeIcon
              color='#2C7BE5'
              icon={faInfoCircle}
              className='ms-3'
            />
          </span>
        </Explainer>
      );
    } else {
      return <div style={{ display: 'inline-block' }}>{children}</div>;
    }
  };
  return (
    <Card.Header className={classNames(className, { 'bg-light': light })}>
      <Row className='align-items-center'>
        {startEl && (
          <Col
            {...{ [breakPoint ? breakPoint : 'xs']: 'auto' }}
            className={`text-left pe-0`}
          >
            {startEl}
          </Col>
        )}
        <Col>
          <TitleExplainer>
            <Title
              titleCapitalize={titleCapitalize}
              breakPoint={breakPoint}
              titleTag={titleTag}
              className={titleClass}
            >
              {title}
            </Title>
          </TitleExplainer>
          {children}
        </Col>
        {endEl && (
          <Col
            {...{ [breakPoint ? breakPoint : 'xs']: 'auto' }}
            className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
          >
            {endEl}
          </Col>
        )}
      </Row>
    </Card.Header>
  );
};

Title.propTypes = {
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleCapitalize: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Title.defaultProps = { titleTag: 'h5' };

FalconCardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  titleExplainerText: PropTypes.string,
  titleCapitalize: PropTypes.bool,
  light: PropTypes.bool,
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  startEl: PropTypes.node,
  endEl: PropTypes.node,
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FalconCardHeader;
