import {
  evmToTronAddress,
  isTronBase58,
  tronToEvmAddress,
} from './tron/convert';
import { z } from 'zod';

import { CHAINS } from 'blockscope/static/supportedChains.js';

const evmAddressSchema = z
  .string()
  .trim()
  .toLowerCase()
  .refine((value) => /^0x[0-9a-f]{40}$/.test(value), {
    message:
      'Invalid EVM address. All EVM addresses are a hex string starting with 0x and 42 characters longs (including 0x).',
  });

const evmTransactionHashSchema = z
  .string()
  .trim()
  .toLowerCase()
  .refine((value) => /^0x[0-9a-f]{64}$/.test(value), {
    message:
      'Invalid EVM transaction hash. All ethereum transaction hashes are a hex string starting with 0x and 66 characters longs (including 0x).',
  });

const isValidAddress = (address, chain) => {
  // regex automatically coerces the input to a string so we need to safeguard if the input is not a string
  if (typeof address !== 'string') {
    return false;
  }

  if (chain === CHAINS.BITCOIN) {
    return /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(address);
  }
  if (chain === CHAINS.TRON) {
    return /^0x[0-9a-fA-F]{40}|T[a-zA-Z0-9]{33}$/.test(address);
  }
  return evmAddressSchema.safeParse(address).success;
};

const isValidTransactionHash = (hash) => {
  return evmTransactionHashSchema.safeParse(hash).success;
};

const formatToEvmAddress = (address, fromChain) => {
  switch (fromChain) {
    case CHAINS.TRON:
      return isTronBase58(address)
        ? tronToEvmAddress(address).toLowerCase()
        : address.toLowerCase();
    default:
      return address.toLowerCase();
  }
};

const formatFromEvmAddress = (address, toChain) => {
  if (isValidAddress(address, CHAINS.ETHEREUM)) {
    switch (toChain) {
      case CHAINS.TRON:
        return !isTronBase58(address) ? evmToTronAddress(address) : address;
      default:
        return address.toLowerCase();
    }
  }
  return address.toLowerCase();
};

export default {
  isValidAddress,
  evmAddressSchema,
  evmTransactionHashSchema,

  isValidTransactionHash,
  formatToEvmAddress,
  formatFromEvmAddress,
};

