import { ORG_SERVICES } from 'blockscope/static/blockscopeServices';

export const CHAINS = {
  ARBITRUM: 'arbitrum',

  AVALANCHE_C: 'avalanche_c',
  BASE: 'base',
  BSC: 'bsc',
  CELO: 'celo',
  ETHEREUM: 'ethereum',
  ETHEREUM_SEPOLIA: 'ethereum-sepolia',
  FANTOM: 'fantom',
  OPTIMISM: 'optimism',
  POLYGON: 'polygon',
  BITCOIN: 'bitcoin',
  TRON: 'tron',
  BLAST: 'blast',
};

export const CLEAN_CHAIN_NAMES = {
  [CHAINS.ARBITRUM]: 'Arbitrum One',

  [CHAINS.AVALANCHE_C]: 'Avalanche C-Chain',
  [CHAINS.BASE]: 'Base',
  [CHAINS.BSC]: 'Binance Smart Chain',
  [CHAINS.CELO]: 'Celo',
  [CHAINS.ETHEREUM]: 'Ethereum',
  [CHAINS.ETHEREUM_SEPOLIA]: 'Ethereum Sepolia',
  [CHAINS.FANTOM]: 'Fantom',
  [CHAINS.OPTIMISM]: 'Optimism',
  [CHAINS.POLYGON]: 'Polygon PoS',
  [CHAINS.BITCOIN]: 'Bitcoin',
  [CHAINS.TRON]: 'Tron',
  [CHAINS.BLAST]: 'Blast',
};

const EVM_CHAINS = [
  CHAINS.ETHEREUM,
  CHAINS.POLYGON,
  CHAINS.BASE,
  CHAINS.FANTOM,
  CHAINS.CELO,
  CHAINS.BSC,
  CHAINS.ARBITRUM,
  CHAINS.OPTIMISM,
];

const chainPriority = {
  [CHAINS.ETHEREUM]: 9999,
  [CHAINS.BITCOIN]: 9998,
  [CHAINS.POLYGON]: 9997,
  [CHAINS.BASE]: 9996,
};

export const SERVICE_CHAINS = {
  [ORG_SERVICES.ADDRESS_ACTIVITY]: [
    CHAINS.ETHEREUM,
    CHAINS.POLYGON,
    CHAINS.OPTIMISM,
    CHAINS.ARBITRUM,
    CHAINS.BASE,
    CHAINS.FANTOM,
    CHAINS.BSC,
    CHAINS.BLAST,
  ],
  [ORG_SERVICES.WATCHTOWER]: [
    CHAINS.ETHEREUM,
    CHAINS.ARBITRUM,
    CHAINS.AVALANCHE_C,
    CHAINS.BASE,
    CHAINS.ETHEREUM_SEPOLIA,
    CHAINS.OPTIMISM,
    CHAINS.POLYGON,
  ],
  [ORG_SERVICES.PIPELINES]: [
    CHAINS.ETHEREUM,
    CHAINS.ARBITRUM,
    CHAINS.AVALANCHE_C,
    CHAINS.BASE,
    CHAINS.ETHEREUM_SEPOLIA,
    CHAINS.OPTIMISM,
    CHAINS.POLYGON,
  ],
  [ORG_SERVICES.ORACLE_MONITORED_ADDRESSES]: [CHAINS.ETHEREUM, CHAINS.POLYGON],
  [ORG_SERVICES.ORACLE]: [CHAINS.ETHEREUM, CHAINS.POLYGON],
  [ORG_SERVICES.CONTRACT_USAGE]: [
    CHAINS.ETHEREUM,
    CHAINS.POLYGON,
    CHAINS.FANTOM,
    CHAINS.CELO,
    CHAINS.ARBITRUM,
    CHAINS.OPTIMISM,
  ],
  [ORG_SERVICES.TRANSACTION_DECODER]: [
    CHAINS.ETHEREUM,
    CHAINS.ETHEREUM_SEPOLIA,
    CHAINS.POLYGON,
    CHAINS.BASE,
    CHAINS.ARBITRUM,
    CHAINS.OPTIMISM,
    CHAINS.BSC,

    CHAINS.BLAST,
    CHAINS.AVALANCHE_C,
  ],
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: [
    CHAINS.ETHEREUM,
    CHAINS.ETHEREUM_SEPOLIA,
    CHAINS.POLYGON,
    CHAINS.BASE,
    CHAINS.ARBITRUM,
    CHAINS.OPTIMISM,
    CHAINS.BSC,

    CHAINS.BLAST,
    CHAINS.AVALANCHE_C,
  ],
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: [
    CHAINS.ETHEREUM,
    CHAINS.POLYGON,
    CHAINS.BASE,
    CHAINS.ARBITRUM,
    CHAINS.OPTIMISM,
    CHAINS.BSC,
    CHAINS.FANTOM,
  ],
  [ORG_SERVICES.BLOCK_BREAKDOWN]: [
    CHAINS.ETHEREUM,
    CHAINS.POLYGON,
    CHAINS.BASE,
    CHAINS.ARBITRUM,
    CHAINS.OPTIMISM,
    CHAINS.BSC,
    CHAINS.BLAST,
    CHAINS.AVALANCHE_C,
  ],
  [ORG_SERVICES.TRACER_TOKENFLOW]: [
    CHAINS.ETHEREUM,
    CHAINS.AVALANCHE_C,
    CHAINS.POLYGON,
    CHAINS.FANTOM,
    CHAINS.CELO,
    CHAINS.ARBITRUM,
    CHAINS.OPTIMISM,
    CHAINS.TRON,
  ],
  LABELS: [
    // CHAINS.BITCOIN,
    CHAINS.ETHEREUM,
    // CHAINS.POLYGON,
    // CHAINS.FANTOM,
    // CHAINS.BASE,
    // CHAINS.ARBITRUM,
    // CHAINS.OPTIMISM,
    // CHAINS.BSC,
  ],
  MEMO: Object.values(CHAINS),
  ALL: Object.values(CHAINS),
  [ORG_SERVICES.EVM_EVENT_DIRECTORY]: EVM_CHAINS,
  [ORG_SERVICES.EVM_FUNCTION_DIRECTORY]: EVM_CHAINS,
  [ORG_SERVICES.ENTITY_INTERACTION]: [
    CHAINS.ETHEREUM,
    CHAINS.POLYGON,
    CHAINS.FANTOM,
    CHAINS.CELO,
    CHAINS.ARBITRUM,
    CHAINS.OPTIMISM,
    CHAINS.TRON,
    CHAINS.AVALANCHE_C,
  ],
};

SERVICE_CHAINS[ORG_SERVICES.TRACER_ANALYTICS] = SERVICE_CHAINS[
  ORG_SERVICES.TRACER_TOKENFLOW
].filter((chain) =>
  SERVICE_CHAINS[ORG_SERVICES.ADDRESS_ACTIVITY].includes(chain)
);

export const chainListByPriority = (chainList) =>
  chainList.sort((a, b) => {
    if (chainPriority[a] || chainPriority[b]) {
      return (chainPriority[b] || 0) - (chainPriority[a] || 0);
    }
    return a > b ? 1 : -1;
  });

export const CHAINS_OPTIONS_LIST = (chains) => {
  return chainListByPriority(chains).map((chain) => {
    return {
      value: chain,
      label: CLEAN_CHAIN_NAMES[chain] || chain,
    };
  });
};

